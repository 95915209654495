export { CarbonNeutral } from './CarbonNeutral'
export { CircularEconomy } from './CircularEconomy'
export { FairTrade } from './FairTrade'
export { MadeInEurope } from './MadeInEurope'
export { MadeInFrance } from './MadeInFrance'
export { NaturalMaterials } from './NaturalMaterials'
export { OptimizedInventory } from './OptimizedInventory'
export { Organic } from './Organic'
export { RecycledMaterials } from './RecycledMaterials'
export { ShortSupplyChain } from './ShortSupplyChain'
export { Sustainable } from './Sustainable'
export { UpcycledMaterials } from './UpcycledMaterials'
export { ZeroWaste } from './ZeroWaste'
